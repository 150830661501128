<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        height="50"
      >
        <v-toolbar-title>Solutions</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          color="accent"
          small
          @click="openAllSolutionDialog"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>Recommended
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn
          color="accent"
          small
          @click="openSolutionDialog"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New Solution
        </v-btn> -->
      </v-toolbar>

      <v-card-text>
        <!-- start of defaults list -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="solutionsData"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <!-- end of defaults table list -->
      </v-card-text>
    </v-card>

    <!-- start of entry dialog -->
    <v-dialog
      v-model="solutionDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dense
          dark
          color="modalToolbar"
        >
          <v-btn
            icon
            dark
            @click="solutionDialog = false"
          >
            <v-icon dark>mdi-keyboard-backspace</v-icon>
          </v-btn>
          <v-toolbar-title>Solution Knowledge Base</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="solutionDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="3">
                <v-text-field
                  outlined
                  dense
                  required
                  label="Item"
                  :append-icon="'mdi-airballoon'"
                  @click:append="openItemModal()"
                  v-model="record.ItemCode"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.StatusNum"
                  item-text="Status"
                  item-value="id"
                  label="Status"
                ></v-autocomplete>
              </v-col>

              <v-col cols="3">
                <v-autocomplete
                  outlined
                  dense
                  readonly
                  filled
                  v-model="record.SltCode"
                  item-text="solution_no"
                  item-value="id"
                  label="No."
                ></v-autocomplete>
              </v-col>

              <v-col cols="3">
                <v-autocomplete
                  outlined
                  dense
                  filled
                  readonly
                  v-model="record.Owner"
                  :items="allUsersData"
                  item-text="name"
                  item-value="id"
                  label="Owner"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  label="Solution"
                  v-model="record.Subject"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  label="Symptom"
                  v-model="record.Symptom"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-tabs
                background-color="accent"
                color="white"
              >
                <v-tab dark>Description</v-tab>
                <v-tab dark>Attachments</v-tab>
                <!--- FIRST TAB -->
                <v-tab-item>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        label="Cause"
                        v-model="record.Cause"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        label="Remarks"
                        v-model="record.Descriptio"
                        document_lines="2"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <!--- SECOND TAB -->
                </v-tab-item>

                <!--- ATTACHMENTS TAB -->
                <v-tab-item>
                  <v-row>
                    <attachment-editor
                      @attachmentsSelected="setAttchments"
                      :initialHeaderRecord="initialHeaderRecord"
                    ></attachment-editor>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="accent"
                  @click="solutionSave"
                >
                  <v-icon left>mdi-content-save</v-icon>Add
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of entry dialog -->

    <!-- one item selection modal -->
    <v-dialog
      v-model="itemDialog"
      width="950"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>List of Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="itemDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Select using Item Code or Item Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="itemMasterHeaders"
                  :items="itemMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :loading="loading"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->

    <!-- one item solution header modal -->
    <v-dialog
      v-model="allSolutionsDialog"
      width="950"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>Recommended Solutions</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="allSolutionsDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchSolution"
                    append-icon="mdi-magnify"
                    label="Search Solution"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedSolution"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="solutionHeadersheaders"
                  :items="serviceCallSolutions"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="accent"
                  @click="recommSave"
                >
                  <v-icon left>mdi-content-save</v-icon>Add
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end one item solution header modal -->

  </v-container>
</template>

<script>
import AttachmentEditor from "./attachment-editor.vue";
export default {
  components: {
    AttachmentEditor,
  },
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },

  computed: {
    CurrentlyLoginUser() {
      return this.$store.state.user;
    },
    allUsersData() {
      return this.$store.state.userdata;
    },
  },

  data: () => ({
    search: "",
    searchSolution: "",
    searchItem: null,
    record: {},
    solutionsData: [],
    itemMasterData: [],
    selectedItems: [],
    serviceCallSolutions: [],
    initialHeaderRecord: {},
    solutionDialog: false,
    itemDialog: false,
    itemsPerPage: 10,
    loading: false,
    singleSelect: true,
    allSolutionsDialog: false,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Solution", value: "Subject" },
      { text: "Created on", value: "created_at" },
      { text: "Owner", value: "creator.name" },
    ],

    solutionHeadersheaders: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Solution", value: "Subject" },
      { text: "Created on", value: "created_at" },
      { text: "Owner", value: "creator.name" },
    ],
    itemMasterHeaders: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Item Description", value: "ItemName" },
      { text: "In Stock", value: "OnHand" },
    ],
    callStatus: [
      { name: "Completeted Jobs", value: "L" },
      { name: "Open", value: "M" },
      { name: "WIP", value: "H" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    searchItem: {
      handler: "searchItemMasterData",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.solutionsData = val.solutions;
    },
    openSolutionDialog() {
      this.solutionDialog = true;
    },

    solutionSave() {
      const url = "/service-calls-solutions";
      const self = this;
      const data = this.record;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
        });
    },

    recommSave() {
      const url = "/service-calls-solutions";
      const self = this;
      const data = this.record;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
        });
    },

    setAttchments(data) {
      this.attachments = data;
    },
    openItemModal() {
      this.itemDialog = true;
    },

    clickedItem($event) {
      const selectedItem = $event.item;

      const details = {
        ItemCode: selectedItem.ItemCode,
      };

      const original = this.record;
      this.record = { ...original, ...details };
      this.itemDialog = false;
    },
    clickedSolution($event) {
      const selectedItem = $event.item;

      // end of find
      const currentSelectedItem = this.solutionsData.find((record) => {
        return record.id === selectedItem.id;
      });

      if (currentSelectedItem) {
        return;
      }
      this.solutionsData.push(selectedItem);
    },
    searchItemMasterData(val) {
      if (!val) {
        return;
      }

      this.fetchEntriesDebounced();
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.getItemMaster();
      }, 500);
    },
    getItemMaster() {
      this.loading = true;
      const self = this;
      this.$store
        .dispatch("get", `/item_masterdata?f=${this.searchItem}`)
        .then((res) => {
          self.itemMasterData = res.ResponseData;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      this.loading = false;
    },
    getData() {
      const self = this;
      this.$store
        .dispatch("get", `/service-calls-solutions`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.serviceCallSolutions = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    setDefaultOwner() {
      this.record.Owner = this.CurrentlyLoginUser.id;
    },
    openAllSolutionDialog() {
      this.allSolutionsDialog = true;
    },
  },
  created() {
    this.setDefaultOwner();
    this.getData();
  },
};
</script>