<template>
  <v-container fluid>
    <v-col cols="8">
      <v-btn
        style="margin-top:-100px"
        color="primary"
        class="mt-1"
        small
        dark
        @click="openAttachmentModal()"
      >
        <v-icon left>mdi-plus</v-icon>Add File
      </v-btn>
      <v-data-table
        light
        :items-per-page="5"
        :headers="serialSelectedHeaders"
        :items="attachments"
        item-key="lastModified"
        hide-default-footer
      >
        <!-- action template -->
        <template v-slot:item.action="{ item }">
          <v-btn
            @click="removeAttachment(item)"
            color="red"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <!-- action template -->
        <template v-slot:item.FileName="{ item }">
          <v-btn
            class="btnLink"
            color="flatButton"
            text
            @click="downloadFile(item)"
          >{{ item.FileName }}</v-btn>
        </template>

        <!-- end -->
      </v-data-table>

    </v-col>
    <v-dialog
      v-model="attachmentDialog "
      max-width="650px"
    >
      <v-card>
        <v-toolbar
          dense
          color="grey"
          dark
        >
          <v-toolbar-title>Add File</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.native="attachmentDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="newAttachment"
                  multiple
                  small-chips
                  show-size
                  label="File input"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  dark
                  @click="addNewFile"
                >Add</v-btn>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
export default {
  data() {
    return {
      attachments: [],
      attachmentDialog: false,
      newAttachment: [],
      serialSelectedHeaders: [
        { text: "Name", value: "FileName" },
        { text: "Action", value: "action" },
      ],
    };
  },
  props: {
    initialHeaderRecord: {
      type: Object,
    },
  },
  watch: {
    attachments: {
      handler: "setFiles",
      immediate: true,
    },
    initialHeaderRecord: {
      handler: "setInitial",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    removeAttachment(item) {
      const dataItem = item;
      const currentFile = this.attachments.find((record) => {
        return record.id === dataItem.id;
      });

      const pos = this.attachments.indexOf(currentFile);
      if (pos === -1) {
        return;
      }
      this.attachments.splice(pos, 1);
    },
    openAttachmentModal() {
      this.newAttachment = null;
      this.attachmentDialog = true;
    },
    addNewFile() {
      const self = this;
      const files = this.newAttachment;
      var allAttachments = self.attachments || [];
      files.forEach((file) => {
        const newItem = {
          id: allAttachments.length + 1,
          FileName: file.name,
          attachmedDetails: file,
        };
        allAttachments.push(newItem);
      });
      this.attachments = allAttachments;
      this.attachmentDialog = false;
    },
    setFiles() {
      this.$emit("attachmentsSelected", this.attachments);
    },
    setInitial(val) {
      this.attachments = val.oats;
    },
    downloadFile(item) {
      window.open(item.realPath, "_blank");
    },
  },
};
</script>